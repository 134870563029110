import React, { Component, Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom'; // Import Route component
import Spinner from '../app/shared/Spinner';
import AuthRoute from './AuthRoute';
import Cookies from 'js-cookie';

// Other route imports...
// const Login = lazy(() => import('./user-pages/Login'));
// const Register1 = lazy(() => import('./user-pages/Register'));

// const Dashboard = lazy(() => import('./dashboard/Dashboard'));


const Login = lazy(() => import('./user-pages/Login'));
const Register1 = lazy(() => import('./user-pages/Register'));
// const QrImage = lazy(() => import('./user-pages/QrImage'));
// const VerificationCode = lazy(() => import('./user-pages/VerificationCode'));
const ForgotPass = lazy(() => import('./user-pages/ForgotPass'));
const ResetPass = lazy(() => import('./user-pages/ResetPass'));

const Dashboard = lazy(() => import('./dashboard/Dashboard'));

const Buttons = lazy(() => import('./basic-ui/Buttons'));
const Dropdowns = lazy(() => import('./basic-ui/Dropdowns'));
const Typography = lazy(() => import('./basic-ui/Typography'));

const BasicElements = lazy(() => import('./form-elements/BasicElements'));

const BasicTable = lazy(() => import('./tables/BasicTable'));

const Mdi = lazy(() => import('./icons/Mdi'));

const ChartJs = lazy(() => import('./charts/ChartJs'));

const CreateUser = lazy(() => import('./permissions/CreateUser'));
const UsersList = lazy(() => import('./permissions/UsersList'));
const CreateRole = lazy(() => import('./permissions/CreateRole'));
const RolesList = lazy(() => import('./permissions/RolesList'));
const Permission = lazy(() => import('./permissions/Permission'));
const InvitationList = lazy(() => import('./permissions/InvitationList'));

const Error404 = lazy(() => import('./error-pages/Error404'));
const Error500 = lazy(() => import('./error-pages/Error500'));

// const Login = lazy(() => import('./user-pages/Login'));
// const Register1 = lazy(() => import('./user-pages/Register'));

class AppRoutes extends Component {
  render() {
    const isAuthenticated = Cookies.get('token');
    console.log("isAuthenticated:", isAuthenticated);

    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
          {/* Public routes */}
          <Route exact path="/user-pages/login-1">
            {isAuthenticated ? <Redirect to="/dashboard" /> : <Login />}
          </Route>
          <Route exact path="/user-pages/register-1">
            {isAuthenticated ? <Redirect to="/dashboard" /> : <Register1 />}
          </Route>  
          
          <Route exact path="/user-pages/forgotpass">
            {isAuthenticated ? <Redirect to="/dashboard" /> : <ForgotPass/> }
            </Route>

          <Route exact path="/user-pages/resetpass">
            {isAuthenticated ? <Redirect to="/dshboard"/> : <ResetPass/> }
          </Route>

          {/* <Route path="/user-pages/qrimage" component={ QrImage } />
          <Route path="/user-pages/verificationcode" component={ VerificationCode } /> */}
          {/* Other public routes... */}

          {/* Protected routes */}
          <AuthRoute path="/dashboard" component={Dashboard} isAuthenticated={isAuthenticated} />

          <AuthRoute path="/basic-ui/buttons" component={ Buttons } isAuthenticated={isAuthenticated} />
          <AuthRoute path="/basic-ui/dropdowns" component={ Dropdowns } isAuthenticated={isAuthenticated} />
          <AuthRoute path="/basic-ui/typography" component={ Typography } isAuthenticated={isAuthenticated} />
          <AuthRoute path="/form-Elements/basic-elements" component={ BasicElements } isAuthenticated={isAuthenticated} />
          <AuthRoute path="/tables/basic-table" component={ BasicTable } isAuthenticated={isAuthenticated} />
          <AuthRoute path="/icons/mdi" component={ Mdi } isAuthenticated={isAuthenticated} />
          <AuthRoute path="/charts/chart-js" component={ ChartJs } isAuthenticated={isAuthenticated} />
          
          <AuthRoute path="/permissions/createuser/:id?" component={ CreateUser } isAuthenticated={isAuthenticated} />
          <AuthRoute path="/permissions/userslist" component={ UsersList } isAuthenticated={isAuthenticated} />
          <AuthRoute path="/permissions/createrole/:id?" component={ CreateRole } isAuthenticated={isAuthenticated} />
          <AuthRoute path="/permissions/roleslist" component={ RolesList } isAuthenticated={isAuthenticated} />
          <AuthRoute path="/permissions/permission/:roleId?" component={Permission} isAuthenticated={isAuthenticated} />
          <AuthRoute path="/permissions/invitationlink" component={ InvitationList } isAuthenticated={isAuthenticated} />

          {/* <Route exact path="/user-pages/login-1" component={ Login } /> */}
          {/* <Route path="/user-pages/register-1" component={ Register1 } /> */}

          <AuthRoute path="/error-pages/error-404" component={ Error404 } isAuthenticated={isAuthenticated} />
          <AuthRoute path="/error-pages/error-500" component={ Error500 } isAuthenticated={isAuthenticated} />
          <Redirect to="/user-pages/login-1" />
          {/* Other protected routes... */}

          {/* Redirect to login for unknown routes */}
          <Redirect to="/user-pages/login-1" />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;
