import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';

// debugger;
const isAuthenticated = () => {
    return !!Cookies.get('token');
  };

const AuthRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to="/user-pages/login-1" />
      )
    )} />
  );

export default AuthRoute;
