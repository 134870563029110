// LOGIN URLS
export const LOGIN_URL = 'http://localhost:3000/login';
export const LOGIN_URLS = 'https://support.deepaiges.com/login';

// REGISTER URLS
export const REGISTER_URL = 'http://localhost:3000/register';
export const REGISTER_URLS = 'https://support.deepaiges.com/register';

// LOGOUT URLS
export const LOGOUT_URL = 'http://localhost:3000/logout';
export const LOGOUT_URLS = 'https://support.deepaiges.com/logout';

// FORGOT_PASSWORD URLS
export const FORGOT_PASSWORD_URL = 'http://localhost:3000/forgot-password';
export const FORGOT_PASSWORD_URLS = 'https://support.deepaiges.com/forgot-password';

// RESET_PASSWORD URLS
export const RESET_PASSWORD_URL = 'http://localhost:3000/reset-password';
export const RESET_PASSWORD_URLS = 'https://support.deepaiges.com/reset-password';



//PERMISSIONS MODULE
// CREATE_ROLE URLS 
export const CREATE_ROLE_URL = 'http://localhost:3000/usersrole';
export const CREATE_ROLE_URLS = 'https://support.deepaiges.com/usersrole';

// UPDATE_ROLE BY ID URLS
export const UPDATE_ROLE_URL = 'http://localhost:3000/usersroles/';
export const UPDATE_ROLE_URLS = 'https://support.deepaiges.com/usersroles/';

// LIST_ROLE BY ID URLS
export const LIST_ROLE_URL = 'http://localhost:3000/rolesuser/';
export const LIST_ROLE_URLS = 'https://support.deepaiges.com/rolesuser/';

// ROLE_LIST URLS
export const ROLE_LIST_URL = 'http://localhost:3000/allroles';
export const ROLE_LIST_URLS = 'https://support.deepaiges.com/allroles';

// CHECK_ROLE_ASSIGNMENT BY ID URLS
export const ROLE_CHECK_URL = 'http://localhost:3000/checkroleassignment/';
export const ROLE_CHECK_URLS = 'https://support.deepaiges.com/checkroleassignment/';

// DELETE_ROLE BY ID URLS
export const DELETE_ROLE_URL = 'http://localhost:3000/deleterole/';
export const DELETE_ROLE_URLS = 'https://support.deepaiges.com/deleterole/';




// CREATE_USER URLS
export const CREATE_USER_URL = 'http://localhost:3000/createuser';
export const CREATE_USER_URLS = 'https://support.deepaiges.com/createuser';

// UPDATE_USER URLS
export const UPDATE_USER_URL = 'http://localhost:3000/createuser/';
export const UPDATE_USER_URLS = 'https://support.deepaiges.com/createuser/';

// LIST_USER BY ID URLS
export const LIST_USER_URL = 'http://localhost:3000/users/';
export const LIST_USER_URLS = 'https://support.deepaiges.com/users/';

// UNIQUE_USER_ROLE URLS
export const UNIQUE_USER_ROLE_URL = 'http://localhost:3000/roles';
export const UNIQUE_USER_ROLE_URLS = 'https://support.deepaiges.com/roles';

// USER_LIST URLS
export const USER_LIST_URL = 'http://localhost:3000/users';
export const USER_LIST_URLS = 'https://support.deepaiges.com/users';

// DELETE_USER BY ID URLS
export const DELETE_USER_URL = 'http://localhost:3000/users/';
export const DELETE_USER_URLS = 'https://support.deepaiges.com/users/';




// SENDING_USER_INVITE URLS
export const USER_INVITE_URL = 'http://localhost:3000/invite';
export const USER_INVITE_URLS = 'https://support.deepaiges.com/invite';

//VALIDATE_INVITATION_URLS
// export const VALIDATE_INVITATION_URL = 'http://localhost:3000/validate-invitation';
// export const VALIDATE_INVITATION_URLS = 'http://localhost:3000/validate-invitation';

// RECENT_USER_INVITE URLS
export const RECENT_USER_INVITE_URL = 'http://localhost:3000/invitations/recent';
export const RECENT_USER_INVITE_URLS = 'https://support.deepaiges.com/invitations/recent';

// ALL_USER_INVITE URLS
export const ALL_USER_INVITE_URL = 'http://localhost:3000/invitations/all';
export const ALL_USER_INVITE_URLS = 'https://support.deepaiges.com/invitations/all';

// DELETE_USER_INVITE URLS
export const DELETE_USER_INVITE_URL = 'http://localhost:3000/invitations/';
export const DELETE_USER_INVITE_URLS = 'https://support.deepaiges.com/invitations/';




// CREATE/UODATE PERMISSIONS URLS
export const PERMISSIONS_URL = 'http://localhost:3000/permissions/update/';
export const PERMISSIONS_URLS = 'https://support.deepaiges.com/permissions/update/';

// LIST_PERMISSIONS URLS
export const LIST_PERMISSIONS_URL = 'http://localhost:3000/permissions';
export const LIST_PERMISSIONS_URLS = 'https://support.deepaiges.com/permissions';